//@ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { vdbURL } from "@Utility/utilils";

function HistoricalPopulation({ newObjectID }) {
    // Assuming objectExpandData is the API response you receive

    const [populationData, setPopulationData] = useState('')

    const createPopulationURL = (newObjectID) => {
        let sourcesUrl = `${vdbURL}IEDZIVOTAJU_SK_AGRAK/GetSkByObjekts/` + newObjectID
        return sourcesUrl;
    }

    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createPopulationURL(newObjectID), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                if (this.responseText) {
                    const newData = JSON.parse(this.responseText);
                    setPopulationData(newData);
                }
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, [/* objectId */]);



    const headers = [
        "ObjektaID",
        "ARIS nosaukums",
        "Iedzīvotāju skaits",
        "Datums",
        "Administratīvā vai teritoriālā piederība",
        "Avots",

    ];

    const dataToRender = Array.isArray(populationData) ? populationData : [];

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        return new Date(year, month - 1, day); // Months are zero-indexed in JavaScript Date
    };

    // Sort dataToRender by date (oldest to newest)
    const sortedData = [...dataToRender].sort((a, b) => {
        const dateA = parseDate(a.datums);
        const dateB = parseDate(b.datums);
        return dateA - dateB; // Ascending order
    });



    return (
        <div className="scroll-table">
            <table className="expand-table">
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((data, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{data.objektaid}</td>
                            <td>{data.ariS_NOS}</td>
                            <td>{data.iedzivotajU_SK}</td>
                            <td>{data.datums}</td>
                            <td>{data.teritorija}</td>
                            <td>{data.avotsSarakstsAVOTS}</td>
                            {/* <td>{data.apraksts}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HistoricalPopulation;