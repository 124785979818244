//@ts-nocheck
import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const ObjStavoklis = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Ģeogrāfiskā objekta stāvoklis</h1>

                <p>Vietvārdu datubāzē iespējams norādīt četrus ģeogrāfiskā objekta stāvokļa veidus:</p>
                <br />

                <ol type="1">
                    <li>
                        <strong>Pastāv</strong> - pašreiz esošs objekts, kurš atbilst norādītajam veidam (piemēram, ciems, skola, mežs, upe u. tml.);<br /><br />
                    </li>

                    <li>
                        <strong>Nepastāv</strong> - ģeogrāfiskais objekts, kurš ir izzudis:
                        <ul>
                            <li><u>viensēta</u> nepastāv, ja tās vietā vairs nav ne ēku drupu, ne ortofoto saskatāmu pamatu (piemēram, Osumi – agrāk viensēta Baltinavas pagastā, tagad šajā zemesgabalā dzīvojamo ēku vairs nav);</li>
                            <li><u>ūdenstilpe</u> nepastāv, ja agrākais ezers ir pilnībā aizaudzis, vai agrākajā dīķī ūdens ir nolaists un aizsprosts izpostīts (piemēram, Laipu dīķis – agrākais dīķis Vērgales pagastā vairs nepastāv);</li>
                            <li><u>ciems</u> nepastāv, ja tajā vairs nav dzīvojamo māju (piemēram, Puzaniški Aglonas pagastā), vai arī ja esošo māju grupa netiek uzskatīta par ciemu (piemēram, Kazinci Vecsalienas pagastā);</li>
                            <li><u>dzelzceļa</u> stacija vai pietura nepastāv, ja ir likvidēts attiecīgais dzelzceļa iecirknis saskaņā ar aktuālajiem uzņēmuma &quot;Latvijas dzelzceļš&quot; datiem (piemēram, Zūras Vārves pagastā, kas atradās likvidētajā iecirknī Ventspils – Liepāja);</li>
                        </ul>
                        <br />
                    </li>

                    <li>
                        <strong>Daļēji izzudis</strong> - ģeogrāfiskais objekts, kurš ir daļēji izzudis, ja tas ir sapostīts vai dabiskā ceļā zaudējis agrākās būtiskās īpašības, bet dabā tā paliekas vēl ir redzamas:
                        <ul>
                            <li><u>ezers</u> ir daļēji aizaudzis, dīķis ir nolaists un nav uzpludināts jau vairāk nekā 5 gadus (piemēram, Spietēnu dīķis Inešu pagastā);</li>
                            <li><u>agrākajā viensētā</u> ēku vietā ir drupas vai ortofoto labi saskatāmi pamati (piemēram, Ķīberaiši Ukru pagastā – agrāk viensēta, tagad ēku vietā drupas);</li>
                            <li><u>ciems</u> uzskatāms par daļēji izzudušu, ja atlikušas mazāk par 3 viensētām vai arī ja tajā vairs nav iedzīvotāju (piemēram, Daukštāni Demenes pagastā – ciemā palikušas mazāk par trim viensētām);</li>
                        </ul>
                        <br />
                    </li>

                    <li>
                        <strong>Nedarbojas</strong> - ģeogrāfiskais objekts, kurš dabā pastāv, bet nepilda tam noteikto funkciju (piemēram, atpūtas bāze &quot;Pipari&quot; – dabā pastāv, bet darbība nenotiek), kā arī apbedīšanai slēgta mūsdienu kapsēta.
                    </li>
                    <br />
                    <li>
                        <strong>Nosusināts/nolaists </strong> - attiecas uz nosusinātiem vai nolaistiem ezeriem, kā arī uz dīķiem, kas nav uzpludināti jau vairākus gadus un nav skaidrs, vai aizsprosts ir vesels vai nav un varētu tikt atkal uzpludināts  (piemēram, Krumešu dīķis Jaunsātu pagastā);
                    </li>
                    <br />
                    <li>
                        <strong>Nezināms </strong> - ģeogrāfiskais objekts, kuram trūkst precīzas informācijas par tā stāvokli (piemēram, Čūsku purviņš Mālupes pagastā).
                    </li>
                </ol>
            </div>
        </>
    );

};

export default ObjStavoklis;
