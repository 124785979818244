//@ts-nocheck
import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const ArvalstuResursi = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Ārvalstu resursi vietvārdu jomā</h1>
                <br />
                <h2>Starptautiskie un ārvalstu resursi vietvārdu jomā:</h2>
                <br />
                <ul>
                    <p><a href="https://unstats.un.org/unsd/ungegn/" target="_blank">UNGEGN</a></p>
                    <p><a href="http://www.eki.ee/knn/ungegn/" target="_blank">UNGEGN Baltijas nodaļa
                    </a></p>
                    <p><a href="http://www.eki.ee/knn/lingid2.htm" target="_blank">Igaunijas Vietvārdu padomes mājaslapa ar saitēm uz vispasaules un nacionālajām vietvārdu datubāzēm un citiem noderīgiem avotiem (Place Names Board of Estonia.Links)
                    </a></p>
                    <p><a href="https://eurogeographics.org/eurogeonames" target="_blank">EuroGeoNames
                    </a></p>
                </ul>
                <br />

                <h2>Kaimiņvalstu ģeogrāfisko nosaukumu resursi:</h2>
                <br />
                <ul>
                    <p><a href="http://vietovardziai.lki.lt/" target="_blank">Lietuvas vietvārdu vārdnīca</a></p>
                    <p><a href="https://www.geoportal.lt/map/" target="_blank">Vietvārdu meklēšana Lietuvas ģeoportālā
                    </a></p>
                    <p><a href="https://pasaulio-vardai.vlkk.lt/" target="_blank">Pasaules vietvārdi lietuviski
                    </a></p>
                    <p><a href="https://xgis.maaamet.ee/knravalik/knr" target="_blank">Igaunijas nacionālais vietvārdu reģistrs
                    </a></p>
                    <p><a href="http://www.eki.ee/knab/knab.htm" target="_blank">Igauņu valodas institūta Igaunijas un pasaules Vietvārdu datubāze igauņu, angļu un krievu valodā</a></p>
                    <p><a href="https://rosreestr.ru/site/activity/geodeziya-i-kartografiya/naimenovaniya-geograficheskikh-obektov/gosudarstvennyy-katalog-geograficheskikh-nazvaniy/" target="_blank">Krievijas ģeogrāfisko nosaukumu valsts katalogs
                    </a></p>

                    <p><a href="https://ate.nca.by/" target="_blank">Baltkrievijas administratīvo un teritoriālo vienību valsts reģistrs
                    </a></p>
                </ul>
                <br />

                <h2>Baltkrievijas apdzīvoto vietu nosaukumu normatīvais katalogs, 2009. g.:</h2>
                <ul>
                    <p><a href="https://raw.githubusercontent.com/OsmBelarus/Databases/master/Daviedniki/Nazvy_nasielenych_punktau.csv" target="_blank">digitizēts (visa valsts)
                    </a></p>
                    <p><a href="https://knihi.com/none/Nazvy_nasielenych_punktau_Respubliki_Bielarus_Viciebskaja_voblasc.html#8" target="_blank">grāmata pdf formātā (Vitebskas apgabals)
                    </a></p>
                </ul>
            </div >
        </>
    );
};

export default ArvalstuResursi;
