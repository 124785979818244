import React from 'react';
import styles from "../../sass/layout/_explanations.module.scss";

const VietvAvoti = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Vietvārdu avoti</h1>

                <p>
                    Vietvārdu datubāzes izpratnē vietvārda avots ir jebkurš rakstīts, kartogrāfisks vai mutvārdu informācijas avots, kurā minēts attiecīgais vietvārds vai tā variants.
                </p>
                <p>
                    Izmantotie vietvārdu un citas informācijas avoti norādīti attiecīgajos Vietvārdu datubāzes ierakstos.
                </p>
                <p>
                    Vietvārdu datubāzē ir šādi avotu veidi:
                </p>

                <table className="celled-table">
                    <th>Avota veids</th>
                    <tr><td>Publicēta literatūra</td></tr>
                    <tr><td>Nepublicēta literatūra</td></tr>
                    <tr><td>Kartes</td></tr>
                    <tr><td>Individuālais avots</td></tr>
                    <tr><td>Oficiāls avots</td></tr>
                    <tr><td>Reģionāls avots</td></tr>
                    <tr><td>Tīmekļa resursi</td></tr>
                    <tr><td>Vēsturiskais normatīvais akts</td></tr>
                    <tr><td>Normatīvais akts</td></tr>
                    <tr><td>Nozares dokuments</td></tr>
                    <tr><td>Vēsturiskie tīmekļa resursi</td></tr>



                </table>

                <br />
                <p>Pilns avotu saraksts atrodams <a href="https://vietvardi.lgia.gov.lv/vv/to_www_help.vietvardu_avoti">šeit</a>. </p>
            </div>
        </>
    );
};

export default VietvAvoti;