//@ts-nocheck
'use client';
import React from "react";
import { useState, useEffect } from "react";
import { vdbURL } from "@Utility/utilils";

function AddressObjectExpand({ newObjectID }: number) {




    const convertStatussAcronyms = (value: string) => {
        if (value === 'DEL') {
            return 'Likvidēts';
        } else if (value === 'EKS') {
            return 'Eksistē';
        } else if (value === 'ERR') {
            return 'Kļūdains';
        }
    };

    const convertApstiprAcronyms = (value: string) => {
        if (value === 'Y') {
            return 'Jā';
        } else {
            return 'Nē';
        }
    }
    const [addressData, setAddressData] = useState([])

    const createAddressURL = (newtestObjectID: number) => {
        let addressUrl = `${vdbURL}ARISARTOBJEKTI/GetArisArtByObjekts/` + newtestObjectID;
        return addressUrl;
    }

    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createAddressURL(newObjectID), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                if (this.responseText) {
                    const newData: any = JSON.parse(this.responseText);
                    setAddressData([newData]);
                }
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, []);


    const headers = [
        "Kods",
        "Nosaukums",
        "Veids",
        "Atribūts",
        "Statuss",
        "Apstiprināts",
        "Modificēts",
        "Teritorijas kods",
        "Teritorijas nosaukums"


    ];

    //const dataToRender = [addressData];

    const dataToRender = Array.isArray(addressData) ? addressData : [];

    //console.log(dataToRender.length > 0 ? dataToRender[0].baseinaid : "No data available");
    //console.log(dataToRender);

    return (
        <div className="scroll-table">
            <table className="expand-table">
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataToRender.map((data, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{data.kods}</td>
                            <td>{data.nosaukums}</td>
                            <td>{data.arisVeids.veidanosaukums}</td>
                            <td>{data.atrib}</td>
                            <td>{convertStatussAcronyms(data.statuss)}</td>
                            <td>{convertApstiprAcronyms(data.apstipr)}</td>
                            <td>{data.daT_MOD}</td>
                            <td>{data.tkods}</td>
                            <td>{data.tnosaukums}</td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AddressObjectExpand;
