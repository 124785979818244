//@ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { vdbURL } from "@Utility/utilils";

function ContainingObjectExpand({ newObjectID }) {
    // Assuming objectExpandData is the API response you receive

    const [containingData, setContainingData] = useState('')

    const createContainingURL = (newObjectID) => {
        let containingUrl = `${vdbURL}OBJEKTI/GetIetilpstosie/` + newObjectID
        return containingUrl;
    }

    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createContainingURL(newObjectID), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                if (this.responseText) {
                    const newData = JSON.parse(this.responseText);
                    setContainingData(newData);
                }
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, [/* objectId */]);



    const headers = [
        "Objekta ID",
        "Pamatnosaukums",
        "Veids",
        "Stāvoklis",
        "Administratīvā vai teritoriālā vienība",
        "Ģeogrāfiskais platums",
        "Ģeogrāfiskais garums",

    ];



    const dataToRender = Array.isArray(containingData) ? containingData : [];

    return (
        <div className="scroll-table">
            <table className="expand-table">
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataToRender.map((data, rowIndex) => (
                        <tr key={rowIndex}>


                            <td>{data.objektaid}</td>
                            <td>{data.pamatnosaukums}</td>
                            <td>{data.veids ? data.veids.veidS3 : 'N/A'}</td> {/* {data.baseins ? data.baseins.baseins : 'N/A'} */}
                            <td>{data.stavoklisNavigation ? data.stavoklisNavigation.stavoklis : 'N/A'}</td>
                            <td>{data.teritorija.novads}</td>
                            <td>{data.geoplatums}</td>
                            <td>{data.geogarums}</td>

                            {/* <td>{data.apraksts}</td> */}

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ContainingObjectExpand;
