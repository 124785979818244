//@ts-nocheck
import React from 'react';

interface ObjectExpandButtonProps {
    onClick: () => void;
    children: React.ReactNode;
}

const ObjectExpandButton: React.FC<ObjectExpandButtonProps> = ({ children, onClick }) => {
    return (
        <button className="object-expand-button" onClick={onClick}>
            <div className="line"></div>
            <div className="children">{children}</div>
            <div className="line"></div>
        </button>
    );
};

export default ObjectExpandButton;