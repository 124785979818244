//@ts-nocheck
import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const LRNormativieAkti = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Latvijas Republikas normatīvie akti</h1>
                <br />
                <h2>Likumi:</h2>
                <ul>
                    <li><a href="https://likumi.lv/doc.php?id=14740" target="_blank">Valsts valodas likums</a></li>
                    <li><a href="https://likumi.lv/doc.php?id=20299" target="_blank">Ģeotelpiskās informācijas likums</a></li>
                    <li><a href="https://likumi.lv/ta/id/315654-administrativo-teritoriju-un-apdzivoto-vietu-likums" target="_blank">Administratīvo teritoriju un apdzīvoto vietu likums</a></li>

                </ul>
                <br />
                <h2>MK noteikumi:</h2>
                <ul>
                    <li><a href="https://likumi.lv/doc.php?id=243610" target="_blank">Vietvārdu informācijas noteikumi</a></li>
                    <li><a href="https://likumi.lv/ta/id/278402-adresacijas-noteikumi" target="_blank">Adresācijas noteikumi</a></li>
                    <li><a href="https://likumi.lv/doc.php?id=142698" target="_blank">Dzelzceļa staciju, izmaiņas punktu un pieturas punktu - publiskās lietošanas dzelzceļa infrastruktūras objektu - atklāšanas, slēgšanas un nosaukuma piešķiršanas kārtība

                    </a></li>

                </ul>

            </div >
        </>
    );
};

export default LRNormativieAkti;
