import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const Koord = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Koordinātas</h1>

                <p>
                    Katram objektam koordinātas ir fiksētas tikai vienā punktā:
                    <br />
                    <ul>
                        <li>
                            laukumveida objektam tas ir objekta teritorijas aptuvenā centrā (piemēram, ezera centrā);
                        </li>
                        <li>
                            ciemam tas var būt arī centrālajā ielu vai ceļu krustojumā;
                        </li>
                        <li>
                            upei – ietekas vietā. Ja upes ieteka atrodas ārpus Latvijas teritorijas, tad koordinātu punkts var atrasties arī vietā, kur upe šķērso Latvijas robežu.
                        </li>
                    </ul>
                </p>
                <br />
                <p>
                    Koordinātu precizitāte ir atbilstoša 1:50 000 mēroga kartei.
                </p>

                <br />


                <table className="celled-table">
                    <tbody>
                        <tr>
                            <td>
                                Ģeogrāfiskais platums
                                <br />
                                Ģeogrāfiskais garums
                            </td>
                            <td>
                                Ģeogrāfiskās koordinātas (DMS) – platums un garums grādos, minūtēs un sekundēs
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Ģeogrāfiskais platums
                                <br />
                                Ģeogrāfiskais garums
                            </td>
                            <td>
                                Ģeogrāfiskās koordinātas (DD) – platums un garums decimālajos grādos
                            </td>
                        </tr>

                        <tr>
                            <td>
                                X koordināta
                                <br />
                                Y koordināta
                            </td>
                            <td>
                                LKS-92 - taisnleņķa metriskās koordinātas Latvijas koordinātu sistēmā
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );

};

export default Koord;
