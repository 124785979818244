import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const VietvTransl = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Paskaidrojumi - citvalodu vietvārdu transliterācijas un atveides noteikumu avoti</h1>
                <p>

                    <br />
                    Datubāzē pieejami tikai tie kaimiņvalstu vietvārdi, kas attiecas uz pārrobežu objektiem (tādiem, kurus šķērso Latvijas un kādas kaimiņvalsts robeža).
                    <br />
                    <br />
                    Kaimiņvalstu vietvārdi datubāzē rādīti attiecīgās valsts valodā.

                    <br /><br />
                    Lietuvas un Igaunijas vietvārdi atveidoti to oriģinālajā rakstībā.
                    <br /><br />
                    Krievijas (RU) un Baltkrievijas (BY) vietvārdi ir pārcelti no kirilicas uz latīņalfabētisko rakstību, izmantojot divas transliterācijas sistēmas: nacionālo (ANO rekomendēto, kas apzīmēta ar civ.) un BPCGN (NATO lietoto, kas apzīmēta ar mil.).
                    <br /><br />

                </p>


                <table className="celled-table">
                    <tr>
                        <th>Saīsinājums</th>
                        <th>Pilns nosaukums, dokuments, kur tā publicēta</th>
                    </tr>

                    <tr>
                        <td>Translit. civ. 1983 RU</td>
                        <td>Report on the Current Status of United Romanization Systems for Geographical Names. Compiled by the UNGEGN Working Group on Romanization Systems. Version 2.2, January 2003. (GOST 1983).</td>
                    </tr>

                    <tr>
                        <td>Translit. civ. 2007 BY</td>
                        <td>Tenth United Nations Conference on the Standardization of Geographical Names. New York, 31 July - 9 August 2012. Item 13(a) of the provisional agenda. Writing systems and pronunciation: Romanization. The Roman alphabet transliteration of Belarusian geographical names. Submitted by Belarus.</td>
                    </tr>

                    <tr>
                        <td>Translit. mil. 1947 RU</td>
                        <td>Romanization System for Russian BGN/OCGN 1947 System.</td>
                    </tr>

                    <tr>
                        <td>Translit. mil. 1979 BY</td>
                        <td>Romanization System for Byelorussian BGN/OCGN 1979 System.</td>
                    </tr>
                </table>
            </div >
        </>
    );
};

export default VietvTransl;
