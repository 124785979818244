import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const SkRakstur = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Skaitliskais raksturojums</h1>

                <p>Skaitliskais raksturojums norādīts tikai tiem objektiem, kurus var ērti un uzskatāmi raksturot ar skaitliskiem rādītājiem. Šajā laukā ir izvēlēts tikai viens - būtiskākais skaitliskais rādītājs:</p>
                <br />
                <ul>
                    <li>pilsētām, ciemiem - iedzīvotāju skaits;</li>
                    <li>mežiem, parkiem, purviem, pļavām - platība (ha);</li>
                    <li>kalniem, pilskalniem - absolūtais augstums (m vjl.);</li>
                    <li>ezeriem, mākslīgām ūdenstilpēm - platība (ha);</li>
                    <li>salām, pussalām - platība (ha);</li>
                    <li>ūdenstecēm - garums (km).</li>
                </ul>

                <br /><br />

                <p>Skaitliskā raksturlieluma avoti:</p>
                <ul>
                    <li>iedzīvotāju skaits pilsētās un ciemos – pēc Pilsonības un migrācijas lietu pārvaldes ziņām;</li>
                    <li>ciemos, kuriem nav oficiāla statusa un kuriem tādēļ iedzīvotāju skaits vairs netiek fiksēts, saglabātas pēdējās pagastu pārvalžu vai kādreizējo pagasta padomju  sniegtās ziņas;</li>
                    <li>dabas objektiem – sākotnēji pēc Satelītkartes (1:50 000 mērogā), pakāpeniski tiek precizēts pēc oficiāliem vai jaunākiem un precīzākiem avotiem.</li>

                </ul>

                <br /><br />

                <p>Skaitliskā raksturlieluma precizitāte ir tāda, kāda tā ir attiecīgajā informācijas avotā.</p>

            </div >
        </>
    );
};

export default SkRakstur;
