//@ts-nocheck
import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const VietvNos = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Vietvārds jeb ģeogrāfiskais nosaukums</h1>

                <p>Vietvārds (ģeogrāfiskais nosaukums, toponīms, vietas nosaukums) ir īpašvārds, kas nosauc konkrētu ģeogrāfisku objektu, piemēram, Gauja, Gaiziņš, Valka utt.</p>

                <p>Vietvārdu datubāzē (VDB) galvenokārt uzkrāti mūsdienu kartēs lietotie ģeogrāfiskie nosaukumi.</p>

                <p>Pārrobežu ģeogrāfiskajiem objektiem (upēm, ezeriem u. c.,) blakus latviskajiem nosaukumiem rādīti arī nosaukumi, kādus lieto attiecīgo kaimiņvalstu teritorijā (Lietuvā, Baltkrievijā, Krievijā un Igaunijā). Šie nosaukumi sniegti oriģinālvalodā, norādot attiecīgo valsti.</p>

                <p>Kaimiņvalstu vietvārdi rādīti attiecīgās valsts oriģinālvalodā. Igaunijas un Lietuvas vietvārdi sniegti to oriģinālajā rakstībā.</p>

                <p>Krievijas un Baltkrievijas vietvārdi atveidoti ar latīņu burtiem saskaņā ar divām transliterācijas sistēmām: nacionālo (ANO rekomendēto, apzīmēta ar civ.) un NATO lietoto (BPCGN, apzīmēta ar mil.). Transliterācijas sistēmu saīsināto pierakstu skaidrojumus skatīt sadaļā &quot;Vietvārdu transliterācija&quot;.</p>

                <p>Aiz nosaukuma iekavās var būt īss paskaidrojums, piemēram, Dzelzkaļi (agrāk) Līkupe (lejtecē); Zachodniaja Dzvina (Baltkrievijā, Translit. civ. 2007 BY) u. tml.</p>

                <p>Vietvārdu datubāzē nosaukumiem 1.12.2024. ir šādi paskaidrojumi:</p>

                <br />

                <table className="celled-table">
                    <th>Paskaidrojums nosaukumam</th>
                    <tr><td>agrāk</td></tr>
                    <tr><td>agrāk arī</td></tr>
                    <tr><td>agrāk augštecē</td></tr>
                    <tr><td>agrāk dokumentos</td></tr>
                    <tr><td>agrāk izloksnē</td></tr>
                    <tr><td>agrāk kartēs</td></tr>
                    <tr><td>agrāk kartēs vācu val.</td></tr>
                    <tr><td>agrāk krievu avotos</td></tr>
                    <tr><td>agrāk lejtecē</td></tr>
                    <tr><td>agrāk lejtecē arī</td></tr>
                    <tr><td>agrāk neprecīzi</td></tr>
                    <tr><td>agrāk patstāvīgs ciems</td></tr>
                    <tr><td>agrāk rakstos</td></tr>
                    <tr><td>agrāk teritoriālā vienība</td></tr>
                    <tr><td>agrāk Translit. civ. 1983 RU</td></tr>
                    <tr><td>agrāk Translit. mil. 1947 RU</td></tr>
                    <tr><td>agrāk vācu avotos</td></tr>
                    <tr><td>agrāk zviedru avotos</td></tr>
                    <tr><td>angliski</td></tr>
                    <tr><td>arī</td></tr>
                    <tr><td>augštecē</td></tr>
                    <tr><td>augštecē arī</td></tr>
                    <tr><td>augštecē kļūdaini</td></tr>
                    <tr><td>augštecē latgaliski</td></tr>
                    <tr><td>augštecē Lietuvā</td></tr>
                    <tr><td>augštecē, tikai literatūrā</td></tr>
                    <tr><td>augštecē, vidustecē</td></tr>
                    <tr><td>austrumdaļā</td></tr>
                    <tr><td>Baltkrievijā, nos. variants, Translit. civ. 2007 BY</td></tr>
                    <tr><td>Baltkrievijā, nos. variants, Translit. mil. 1979 BY</td></tr>
                    <tr><td>Baltkrievijā, Translit. civ. 2007 BY</td></tr>
                    <tr><td>Baltkrievijā, Translit. civ. 2007 BY, Translit. mil. 1979 BY</td></tr>
                    <tr><td>Baltkrievijā, Translit. mil. 1979 BY</td></tr>
                    <tr><td>baltkrieviski</td></tr>
                    <tr><td>daļēji izloksnē</td></tr>
                    <tr><td>dienvidaustrumu daļā</td></tr>
                    <tr><td>dienviddaļā</td></tr>
                    <tr><td>dienvidrietumu daļā</td></tr>
                    <tr><td>ģenitīva forma VVC atzinumā</td></tr>
                    <tr><td>iesauka</td></tr>
                    <tr><td>Igaunijā</td></tr>
                    <tr><td>Igaunijā agrāk</td></tr>
                    <tr><td>Igaunijā arī</td></tr>
                    <tr><td>Igaunijā augštecē</td></tr>
                    <tr><td>Igaunijā augštecē, vidustecē</td></tr>
                    <tr><td>Igaunijā tagad</td></tr>
                    <tr><td>Igaunijā vidustecē</td></tr>
                    <tr><td>igauniski</td></tr>
                    <tr><td>izloksnē</td></tr>
                    <tr><td>izloksnē arī</td></tr>
                    <tr><td>iztaisnotais posms</td></tr>
                    <tr><td>īslaicīgi</td></tr>
                    <tr><td>kartē kļūdaini</td></tr>
                    <tr><td>kartē kļūdains novietojums</td></tr>
                    <tr><td>kartēs arī</td></tr>
                    <tr><td>kartēs kļūdaini</td></tr>
                    <tr><td>kļūdaini</td></tr>
                    <tr><td>Krievijā</td></tr>
                    <tr><td>Krievijā, Translit. civ. 1983 RU</td></tr>
                    <tr><td>Krievijā, Translit. civ. 1983 RU, apvērstā</td></tr>
                    <tr><td>Krievijā, Translit. civ. 1983 RU, Translit. mil. 1947 RU</td></tr>
                    <tr><td>Krievijā, Translit. mil. 1947 RU</td></tr>
                    <tr><td>krieviski</td></tr>
                    <tr><td>krieviski arī</td></tr>
                    <tr><td>latgaliski</td></tr>
                    <tr><td>latgaliski arī</td></tr>
                    <tr><td>Latvijā</td></tr>
                    <tr><td>Latvijā un Lietuvā</td></tr>
                    <tr><td>Latvijā; Baltkrievijā Translit. civ. 2007 BY</td></tr>
                    <tr><td>Latvijā; Krievijā Translit. civ. 1983 RU</td></tr>
                    <tr><td>Latvijā; Krievijā Translit. civ. 1983 RU, Translit. mil. 1947 RU</td></tr>
                    <tr><td>Latvijā; Translit. civ. 2007 BY, Translit. mil. 1979 BY</td></tr>
                    <tr><td>latviski</td></tr>
                    <tr><td>lejtecē</td></tr>
                    <tr><td>lejtecē agrāk</td></tr>
                    <tr><td>lejtecē agrāk arī</td></tr>
                    <tr><td>lejtecē arī</td></tr>
                    <tr><td>lejtecē un vidustecē</td></tr>
                    <tr><td>lejtecē un vidustecē arī</td></tr>
                    <tr><td>Lietuvā</td></tr>
                    <tr><td>Lietuvā arī</td></tr>
                    <tr><td>Lietuvā augštecē</td></tr>
                    <tr><td>likvidēts</td></tr>
                    <tr><td>literarizēti</td></tr>
                    <tr><td>lībiski</td></tr>
                    <tr><td>lībiski arī</td></tr>
                    <tr><td>nepārbaudīts nosaukums</td></tr>
                    <tr><td>nepārbaudīts nosaukums augštecei</td></tr>
                    <tr><td>neprecīzi</td></tr>
                    <tr><td>neprecīzi literarizēti</td></tr>
                    <tr><td>nodibinājums</td></tr>
                    <tr><td>nos. variants, Translit. civ. 2007 BY</td></tr>
                    <tr><td>nos. variants, Translit. mil. 1979 BY</td></tr>
                    <tr><td>nosaukuma variants, Translit. civ. 2007 BY</td></tr>
                    <tr><td>nosaukuma variants, Translit. mil. 1979 BY</td></tr>
                    <tr><td>nosaukums saimniecības ēkām</td></tr>
                    <tr><td>nosaukums viesu namam</td></tr>
                    <tr><td>nosaukums zemes īpašumam</td></tr>
                    <tr><td>nozares dots nosaukums</td></tr>
                    <tr><td>padomju laikā</td></tr>
                    <tr><td>padomju laikā arī</td></tr>
                    <tr><td>pareizāk</td></tr>
                    <tr><td>pašlaik</td></tr>
                    <tr><td>nosaukums pašvaldības uzņēmumam</td></tr>
                    <tr><td>nosaukums pārvaldes struktūrvienībai</td></tr>
                    <tr><td>posms augštecē</td></tr>
                    <tr><td>posms augštecē arī</td></tr>
                    <tr><td>posms lejtecē</td></tr>
                    <tr><td>posms vidustecē</td></tr>
                    <tr><td>rakstos</td></tr>
                    <tr><td>rakstos arī</td></tr>
                    <tr><td>rakstos arī (iespējams, kļūdaini)</td></tr>
                    <tr><td>rakstos kļūdaini</td></tr>
                    <tr><td>rietumdaļā</td></tr>
                    <tr><td>Satelītkartē</td></tr>
                    <tr><td>Satelītkartē kļūdaini</td></tr>
                    <tr><td>senāk</td></tr>
                    <tr><td>senāk lībiski</td></tr>
                    <tr><td>tagad</td></tr>
                    <tr><td>tautas nosaukums arī</td></tr>
                    <tr><td>tautasdziesmās</td></tr>
                    <tr><td>tikai rakstos</td></tr>
                    <tr><td>Translit. civ. 1983 RU</td></tr>
                    <tr><td>Translit. civ. 1983 RU, apvērstā</td></tr>
                    <tr><td>Translit. civ. 1983 RU, Translit. mil. 1947 RU</td></tr>
                    <tr><td>Translit. civ. 2007 BY</td></tr>
                    <tr><td>Translit. civ. 2007 BY, apvērstā</td></tr>
                    <tr><td>Translit. civ. 2007 BY, Translit. mil. 1979 BY</td></tr>
                    <tr><td>Translit. mil. 1947 RU</td></tr>
                    <tr><td>Translit. mil. 1947 RU, apvērstā</td></tr>
                    <tr><td>Translit. mil. 1979 BY</td></tr>
                    <tr><td>Translit. mil. 1979 BY, apvērstā</td></tr>
                    <tr><td>Translit. mil. 1979 BY, Translit. civ. 1983 RU, Translit. mil. 1947 RU</td></tr>
                    <tr><td>vēsturiskajos avotos</td></tr>
                    <tr><td>vidusdaļā</td></tr>
                    <tr><td>vidusdaļā agrāk</td></tr>
                    <tr><td>vidusdaļā Lietuvā</td></tr>
                    <tr><td>vidustecē</td></tr>
                    <tr><td>vidustecē agrāk</td></tr>
                    <tr><td>vidustecē arī</td></tr>
                    <tr><td>vidustecē neprecīzi</td></tr>
                    <tr><td>vidustecē, lejtecē</td></tr>
                    <tr><td>vietējā forma VVC atzinumā</td></tr>
                    <tr><td>vietējā ģenitīva forma VVC atzinumā</td></tr>
                    <tr><td>vietējā sarunu valodā</td></tr>
                    <tr><td>zemes gabala nosaukums</td></tr>
                    <tr><td>zemes gabala nosaukums kaimiņos</td></tr>
                    <tr><td>ziemeļaustrumu daļā</td></tr>
                    <tr><td>ziemeļdaļā</td></tr>
                    <tr><td>ziemeļdaļā arī</td></tr>

                </table>

            </div >
        </>
    );
};

export default VietvNos;