//@ts-nocheck

'use client';

import { createContext, useContext, Dispatch, SetStateAction, useState, Suspense } from "react";
import dynamic from "next/dynamic";
import React, { useEffect, useRef } from 'react';
import { useSearchParams } from "next/navigation";

import { useEffectOnce } from '@Utility/customHook';
import { vdbURL } from '@Utility/utilils';
import { trackSearchEvent, trackExcelCreateEvent } from '@Utility/utilils';
import { capitalizeFirstLetter } from "@Utility/utilils";



import AdmPieder from '@Components/explanations/AdmPieder';
import Koord from '@Components/explanations/Koord';
import ObjStavoklis from '@Components/explanations/ObjStavoklis';
import ObjVeidi from '@Components/explanations/ObjVeidi';
import SkRakstur from '@Components/explanations/SkRakstur';
import VietvAvoti from '@Components/explanations/VietvAvoti';
import VietvIzr from '@Components/explanations/VietvIzr';
import VietvNos from '@Components/explanations/VietvNos';
import VietvVeidi from '@Components/explanations/VietvVeidi';
import VietvTransl from '@Components/explanations/VietvTransl';
import Ietilp from '@Components/explanations/Ietilp';
import LRNormativieAkti from '@Components/explanations/LRNormativieAkti';
import Ieteikumi from '@Components/explanations/IeteikumiVietvarduJoma';
import ArvalstuResursi from '@Components/explanations/ArvalstuResursi';

import WelcomeWindow from '@Components/_welcome-window'; import { v4 as uuidv4, v5 as fromString } from "uuid";


import Header from '@Components/_header';
import Menu from '@Components/_menu';
import Search from '@Components/_search';
//import Tabs from '@Components/_tabs';

import ObjectView from '../search/[id]';
import CookieConsentDialog from '@Components/_cookieConsentDialog';
import MobileWarning from '@Components/_mobile-warning';

import { useRouter } from 'next/navigation';


interface ContextProps {
    userId: string,
    tabComponents: any,

}

type SearchValues = {

    objectID: number;
    objectName: string;
    objectType: string;
    objectCountry?: number;
    objectMunicipality?: number;
}

type TabProps = {
    id: string;
    component: any; // avoid any
    title: string;
    type: string;
    objectID?: number;
    searchParameters?: string[];
    activeObjectId?: number;
}

type CheckboxState = {
    [key: string]: boolean;
};

type MunicipalityOption = {
    value: string;
    label: string;
};

const GlobalContext = createContext<ContextProps>({
    data: [],

})

export const GlobalContextProvider = ({ children }: any) => {




    const checkboxOptions: any = [
        { name: "exact", label: " Meklēt precīzi" },
        { name: "ignoreDiacritics", label: " Meklēt ignorējot diakritiskās zīmes" },
        { name: "officialNamesOnly", label: " Meklēt tikai oficiālos nosaukumus" },
        { name: "searchInSources", label: " Meklēt avotos" },
        { name: "lgiaApprovedOnly", label: " Meklēt tikai ar LĢIA lēmumu apstiprinātos" },
    ];

    //nevis excludedCountries bet included countries
    const includedCountries = ["Sēlija", "Vidzeme", "Latgale", "Kurzeme", "Zemgale"];

    const tabComponents = {

        'Vietvārds jeb nosaukums': <VietvNos />,
        'Vietvārdu veidi': <VietvVeidi />,
        'Objektu veidi': <ObjVeidi />,
        'Vietvārdu avoti': <VietvAvoti />,
        'Skaitliskais raksturojums': <SkRakstur />,
        'Objektu stāvoklis': <ObjStavoklis />,
        'Koordinātas': <Koord />,
        'Administratīvā piederība': <AdmPieder />,

        'Vietvārdu transliterācija': <VietvTransl />,
        'Ietilpstošie (ietekošie) objekti': <Ietilp />,
        'LR normatīvie akti': <LRNormativieAkti />,
        'Ieteikumi vietvārdu jomā': <Ieteikumi />,
        'Ārvalstu resursi vietvārdu jomā': <ArvalstuResursi />



    };

    const [tabs, setTabs] = useState<TabProps[]>(() => {
        const id = uuidv4();

        return [
            { id, component: <WelcomeWindow key={id} />, title: "Sākums", type: 'welcome' },
        ]
    });
    const [activeTab, setActiveTab] = useState('');
    const [tabCollection, setTabCollection] = useState([])
    const [filteredTabCollection, setFilteredTabCollection] = useState({})

    //MENU
    const [isMenuOpen, setMenu] = useState<boolean>(false);

    //derētu apskatīties secību kā strādā
    const [checkboxes, setCheckboxes] = useState<CheckboxState>(
        checkboxOptions.reduce((obj: any, option: any) => ({ ...obj, [option.name]: false }), {})
    );

    //pārskatamībai vajadzētu atdalīt katru atsevišķi
    const [searchValues, setSearchValues] = useState<SearchValues>({

        objectID: 0,
        objectName: '',
        objectType: '',
        objectCountry: 0,
        objectMunicipality: 0
    });

    //countryID un countryData varētu atrasties zem viena array, kā individuāli objekti ar ID un nosaukumu katram.
    const [countryID, setCountryID] = useState<string>(''); //LV vesturiska zeme/valsts
    const [countryData, setCountryData] = useState<string[]>([]);

    const [municipalityData, setMunicipalityData] = useState<string[]>([]); //novads, pagasts/bijusais rajons
    const [uniqueTypes, setUniqueTypes] = useState<{ value: any; label: any; }[]>([]); //objekta veida arrays, visi veidi
    const [data, setData] = useState<{ id: String; data: [] }>({ id: '', data: [] }); //visi ienakosie dati//izlabot table/tabs to, ka neiet bez initial data
    const [filteredData, setFilteredData] = useState<{ id: String; data: [] }>({ id: '', data: [] });

    const [objectTabMap, setObjectTabMap] = useState<{ [key: number]: number }>({});
    const [rowObjectID, setRowObjectID] = useState<number>(0);
    const [rowNosaukums, setRowNosaukums] = useState<string>('');
    const [rowVeids, setRowVeids] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [previousData, setPreviousData] = useState<any[]>([]); //gaujas piemers. uzlabot pie "dzēst mērīšanas perimetru"
    const [objectData, setObjectData] = useState<any[]>([]);
    const [showCookieDialog, setShowCookieDialog] = useState<boolean>(false);
    const [url, setUrl] = useState<String>('')
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const [idFieldActive, setIdFieldActive] = useState<boolean>(true);
    const [showWarning, setShowWarning] = useState<boolean>(true);
    const [userId, setUserId] = useState('');
    const [activeObjectId, setActiveObjectId] = useState<number>(0);
    const [activeSearchParameters, setActiveSearchParameters] = useState<String>("")
    const searchParams = useSearchParams();
    const idFromURL: number = searchParams.get("objectID");
    const explainItemFromUrl = searchParams.get("explain");
    const router = useRouter();
    const idFromUrl: number = searchParams.get("id") ?? 0;
    const regionaIDFromUrl = searchParams.get("regionaID") ?? 0;
    const rajonaIDFromUrl = searchParams.get("rajonaID") ?? 0;
    const veidiFromUrl = searchParams.get("veidi");
    const nosaukDalaFromUrl = searchParams.get("nosaukDala");
    const nosExactFromUrl = searchParams.get("nosExact") ?? ('false');
    const citataDalaFromUrl = searchParams.get("citataDala") ?? ('false');
    const nosOfficialFromUrl = searchParams.get("nosOfficial") ?? ('false');
    const nosNonAccentFromUrl = searchParams.get("nosNonAccent") ?? ('false')
    const nosLGIAApprovedFromUrl = searchParams.get("nosLGIAApproved") ?? ('false');
    let selectedURL = '';


    //USEEFFECT

    useEffectOnce(() => {
        fetchNames();
        fetchUniqueTypes();
        fetchMunicipalities();
        setActiveTab(tabs[0].id)
    }, [])

    useEffect(() => {
        fetchMunicipalities(countryID);
    }, [countryID])


    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookies');
        if (cookiesAccepted === null) {
            setShowCookieDialog(true); // Show the dialog when "cookies" is not set
        } else {
            setShowCookieDialog(false); // Do not show the dialog when "cookies" is set to any value
        }
    }, []);


    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [filteredTabCollection])

    useEffect(() => {
        const handleResize = () => {
            setShowWarning(window.innerWidth > 500);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffectOnce(() => {
        if (idFromURL) {
            handleObjectViewCreate(idFromURL, '', '')
        };
    }, []);

    useEffectOnce(() => {
        if (explainItemFromUrl) {
            handleOpenExplanation(explainItemFromUrl)
        };
    }, []);

    useEffectOnce(() => {
        if (
            idFromUrl ||
            regionaIDFromUrl ||
            rajonaIDFromUrl ||
            veidiFromUrl ||
            nosaukDalaFromUrl

        ) {
            handleFetchAPI();
        }
    }, []);

    const createURLFromSearchParameters = (objID, regionaID, rajonaID, objVeids, nosaukDala, nosExact, citataDala, oficNos, nonAccentNos, LGIAapstipr) => {
        let newUrl = `${vdbURL}OBJEKTI/filterfull?objektaID=` + objID + "&regionaID=" + regionaID + "&rajonaID=" + rajonaID +
            "&veidi=" + objVeids + "&nosaukDala=" + nosaukDala + "&nosExact=" + nosExact + "&citataDala=" + citataDala + "&nosOficial=" + oficNos +
            "&nosNonAccent=" + nonAccentNos + "&nosLGIAApproved=" + LGIAapstipr + "&sortInfo=1"

        setUrl(newUrl)
        return newUrl;
    }

    //REF
    const newObjID = useRef(searchValues.objectID ? searchValues.objectID : 0);
    let tabsRef = useRef(tabs);

    //COOKIES


    const handleAcceptCookies = () => {
        setShowCookieDialog(false);
        localStorage.setItem('cookies', 'accept')

    }

    const handleAcceptMandatoryCookies = () => {
        setShowCookieDialog(false)
        localStorage.setItem('cookies', 'accept-mandatory')
    }

    const handleChangeCookies = () => {
        setShowCookieDialog(false)
        localStorage.setItem('cookies', 'change-cookie-settings')
    }

    //MOBILE WARNING

    const handleCloseWarning = () => {
        setShowWarning(false)
    }

    //MENU
    const handleMenu = () => {
        setMenu(!isMenuOpen);
    }

    const handleOpenExplanation = (tabTitle: string) => {
        const newTabId = uuidv4();
        const newTabComponent = tabComponents[tabTitle] || null;
        const newTab = {
            id: newTabId,
            component: newTabComponent,
            title: tabTitle,
            type: 'explanation',
        };

        handleTabAdd(newTab);

    };


    //SEARCH




    const handleSearchVisibility = () => {
        setSearchVisibility(!searchVisibility);
    }


    const handleCheckboxChange = (name: string, isChecked: boolean) => {
        setCheckboxes((prevCheckboxes: any) => ({
            ...prevCheckboxes,
            [name]: isChecked,
        }));
    };

    const handleSearchButtonClick = async () => {
        setIsLoading(true);

        try {
            const newFilteredData = await filterData();
            setData(newFilteredData);
        } catch (error) {
            // Handle the error and display an error message
            console.error('Error occurred while fetching data:', error);
            setIsLoading(false);
        }
    };

    const handleObjectTypeSearch = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const selectedType = value === "Visi" ? '' : value;

        handleSearchValues({ ...searchValues, objectType: selectedType });
    };

    const handleSearchValues = (newValues: any) => {
        setSearchValues(newValues);
    };

    const handleObjectIDSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        console.log(value)
        if (value.trim() !== '') {
            // If ID field has a value, deactivate other input fields
            setIdFieldActive(false);
        } else {
            // If ID field is empty, activate other input fields
            setIdFieldActive(true);
        }
        handleSearchValues({ ...searchValues, objectID: value });
    };

    const handleObjectNameSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        handleSearchValues({ ...searchValues, objectName: value });
    };

    const handleObjectCountrySearch = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const newCountryID: string | number = value === '0' ? '' : value;
        const newValue = value === "Visi" ? '' : value
        handleSearchValues({ ...searchValues, objectCountry: newValue });
        //searchValues ieliek objektaID ka string, butu jabut numuram.
        setCountryID(newCountryID)

    }

    const handleObjectMunicipalitySearch = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const newValue = value === "Visas adm. vienības" ? 0 : value
        handleSearchValues({ ...searchValues, objectMunicipality: newValue });

    }

    const handleClearSearch = () => {
        console.log(true)
        const initialSearchValues = {

            objectID: 0,
            objectName: '',
            objectType: '',
            objectCountry: 0,
            objectMunicipality: 0
        };
        setIdFieldActive(true)
        setSearchValues(initialSearchValues)
        const resetCheckboxes: CheckboxState = Object.fromEntries(
            Object.keys(checkboxes).map((key) => [key, false])
        );
        setCheckboxes(resetCheckboxes);
    }

    //TABS






    const handleTabAdd = (newTab) => {
        const isExisting = tabsRef.current.find((tab) => tab.id === newTab.id)

        if (isExisting) {
            handleActiveTab(newTab.id)
            return;
        }


        if (!searchValues) {

            setSearchValues.objectID(Number(searchParameters.get("id"))),
                setSearchValues.objectMunicipality(searchParameters.get("regionaID")),
                setSearchValues.objectCountry(searchParameters.get("rajonaID")),
                setSearchValues.objectType(searchParameters.get("veidi")),
                setSearchValues.objectName(searchParameters.get("nosaukDala"))

        }

        const tab = {
            ...newTab,
            searchValues
        };


        const oldTabs = tabsRef.current;
        oldTabs.push(newTab);
        tabsRef.current = oldTabs;
        setTabs(tabs => [...oldTabs]);
        handleActiveTab(newTab.id);
    }

    const handleTabRemove = (tabId) => {
        const tabIndex = tabsRef.current.findIndex((tab) => tab.id === tabId);
        const oldTabs = tabsRef.current;

        const newActiveTab = tabId === activeTab ? (tabIndex + 1) < oldTabs.length ? oldTabs[tabIndex + 1].id : oldTabs.length !== 1 ? oldTabs[tabIndex - 1].id : '' : activeTab;

        oldTabs.splice(tabIndex, 1);
        tabsRef.current = oldTabs;
        setTabs(tabs => [...oldTabs]);
        handleActiveTab(newActiveTab)
    }

    const updateURLWithStringSearchParams = (urlstring) => {
        if (urlstring) {
            const newURL = urlstring;
            router.push(newURL, undefined, { shallow: true });
        }
    }

    const handleActiveTab = (tabId: number) => {

        const tab = tabsRef.current.find(tab => tab.id === tabId);

        if (!tab) {
            setActiveTab(tabId);
            router.push("/search", undefined, { shallow: true });
        }

        else if (tab.type === "welcome") {
            setActiveTab(tabId);
            router.push("/search", undefined, { shallow: true });
        }

        else if (tab.type === "explanation") {

            setActiveTab(tabId);
            router.push(`/search?explain=${tab.title}`, undefined, { shallow: true });
        }


        else if (tab) {


            const tempActiveObjectId = tab.id;
            const tempActiveSearchParameters = tab.searchParameters;
            setActiveSearchParameters(tab.searchParameters)
            console.log(tempActiveObjectId)


            if (tab.type === "objectview") { setActiveObjectId(tempActiveObjectId) }

            setActiveTab(tabId);

            if (!initialLoad) {
                updateURLWithStringSearchParams(tempActiveSearchParameters);
            }

        } else {
            console.error("Tab not found with ID:", tabId);
        }
    }



    const handleObjectViewCreate = (id, nosaukums, veids) => {
        const tab = {
            id: id,
            component: <ObjectView key={id} objectId={id} rowObjectID={rowObjectID} setRowObjectID={setRowObjectID} activeObjectId={activeObjectId} />,
            title: veids || nosaukums ? `${capitalizeFirstLetter(veids)}: ${nosaukums}` : 'Rezultāti',
            type: 'objectview',
        }
        handleTabAdd(tab)
    }

    const handleTabCollection = (newTabCollection) => {
        setTabCollection(tabCollection => [...newTabCollection]);
    }

    const handleFilteredTabCollection = (newFilteredTabCollection) => {
        /*  setFilteredData(prevData => ({ ...prevData, id: newData.id, data: newData.data })) */
        setFilteredTabCollection(newFilteredTabCollection);
    }

    //DATA
    const filterData = async () => {
        const newFilteredData = data.filter((item: any) => {
            const { VEIDS, OBJECTID, pamatnosaukums } = item.properties;
            if (checkboxes.exact) {
                return (

                    (!searchValues.objectName || (pamatnosaukums && pamatnosaukums === searchValues.objectName)) &&
                    (!searchValues.objectType || (VEIDS && VEIDS === searchValues.objectType)) &&
                    (!searchValues.objectID || (OBJECTID && OBJECTID === searchValues.objectID))
                );

            } else {
                return (
                    (!searchValues.objectName || (pamatnosaukums && pamatnosaukums.includes(searchValues.objectName))) &&
                    (!searchValues.objectType || (VEIDS && VEIDS.includes(searchValues.objectType))) &&
                    (!searchValues.objectID || (OBJECTID && OBJECTID.includes(searchValues.objectID)))
                );
            }
        });

        return newFilteredData;
    };

    const fetchNames = () => {
        fetch(`${vdbURL}TERIT_STAT_REGIONI`)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setCountryData(
                        data
                            .filter(({ nosaukums }) => includedCountries.includes(nosaukums))
                            .map(({ tsR_ID, nosaukums }) => ({
                                value: tsR_ID,
                                label: nosaukums,
                            }))
                    );
                }
            })
            .catch(error => {
                console.error('Error fetching names:', error);
            });

    };

    const fetchMunicipalities = (countryID: number) => {
        const countryUrl = (`${vdbURL}ArisArtObjekti/Municipal/${countryID || ''}`);
        fetch(countryUrl)
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0) {
                    const allMunicipalities = [{ kods: 0, nosaukums: 'Visas adm. vienības' }, ...data];
                    setMunicipalityData(allMunicipalities);
                }
            })
            .catch(error => {
                console.log('Error fetching names:', error);
            });
    };

    const municipalityOptions: MunicipalityOption[] = municipalityData.map((item: any) => {
        return {
            value: item.kods,
            label: item.nosaukums
        }
    })


    const fetchUniqueTypes = () => {
        fetch(`${vdbURL}VEIDI/unique`)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    const allTypes = [['Visi'], ...data].map((value, index) => ({
                        value: value,
                        label: value
                    }));
                    setUniqueTypes(allTypes);
                }
            })
            .catch(error => {
                //console.error('Error fetching unique types:', error);
            });
    };

    const createURL = () => {
        let objID: number = searchValues.objectID ? searchValues.objectID : 0;
        let regionaID: number = searchValues.objectCountry ? searchValues.objectCountry : 0;
        let rajonaID: number = searchValues.objectMunicipality ? searchValues.objectMunicipality : 0;
        let objVeids: string = encodeURIComponent(searchValues.objectType);
        let nosaukDala: string = encodeURIComponent(searchValues.objectName);
        let nosExact: any = checkboxes.exact;
        let citataDala: boolean = checkboxes.searchInSources;
        let oficNos: boolean = checkboxes.officialNamesOnly;
        let nonAccentNos: boolean = checkboxes.ignoreDiacritics;
        let LGIAapstipr: boolean = checkboxes.lgiaApprovedOnly;

        let newUrl = `${vdbURL}OBJEKTI/filterfull?objektaID=` + objID + "&regionaID=" + regionaID + "&rajonaID=" + rajonaID +
            "&veidi=" + objVeids + "&nosaukDala=" + nosaukDala + "&nosExact=" + nosExact + "&citataDala=" + citataDala + "&nosOficial=" + oficNos +
            "&nosNonAccent=" + nonAccentNos + "&nosLGIAApproved=" + LGIAapstipr + "&sortInfo=1"

        setUrl(newUrl)
        return newUrl;

    }

    const updateURLWithSearchParams = () => {

        /*         let newURL = tabs.searchParameters
                router.push(newURL, undefined, { shallow: true });
                console.log(newURL)
                setInitialLoad(false) */


        /*             let objID: any = searchValues.objectID ? searchValues.objectID : 0;
                    let regionaID: number | undefined = searchValues.objectCountry;
                    let rajonaID: number = searchValues.objectMunicipality ? searchValues.objectMunicipality : 0;
                    let objVeids: string = encodeURIComponent(searchValues.objectType);
                    let nosaukDala: string = encodeURIComponent(searchValues.objectName);
                    let nosExact: any = checkboxes.exact;
                    let citataDala: boolean = checkboxes.searchInSources;
                    let oficNos: boolean = checkboxes.officialNamesOnly;
                    let nonAccentNos: boolean = checkboxes.ignoreDiacritics;
                    let LGIAapstipr: boolean = checkboxes.lgiaApprovedOnly;
            
            
            
                    const queryParams = new URLSearchParams(window.location.search);
                    queryParams.delete('objectID');
                    const newURL = `/search?id=${objID}&regionaID=${regionaID}&rajonaID=${rajonaID}&veidi=${objVeids}&nosaukDala=${nosaukDala}&nosExact=${nosExact}&citataDala=${citataDala}&nosOficial=${oficNos}&nosNonAccent=${nonAccentNos}&nosLGIAApproved=${LGIAapstipr}&sortInfo=1`;
                    router.push(newURL, undefined, { shallow: true });
            
             */
    }

    const createObjectURL = (rowObjectID: number) => {
        let objectUrl = `${vdbURL}OBJEKTI/filterfull?objektaID=` + rowObjectID + "&regionaID=0&rajonaID=0&veidi=&nosaukDala=&nosExact=false&citataDala=false&nosOficial=false&nosNonAccent=false&nosLGIAApproved=false&sortInfo=1"
        return objectUrl;
    }



    const createSwaggerURL = (ids: number[]) => {
        const idString = ids.join(',');
        let swaggerURL = `${vdbURL}OBJEKTI/GetByMultipleIDs?ids=` + idString;
        return swaggerURL;
    }

    const showAlertFor3Seconds = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };

    const [initialLoad, setInitialLoad] = useState(true);

    const handleFetchAPI = async () => {
        setIsLoading(true); // Start the loading animation
        let url;
        console.log(initialLoad)
        if (!searchValues.objectType &&
            !searchValues.objectID &&
            !searchValues.objectName &&
            !searchValues.objectCountry &&
            !searchValues.objectMunicipality &&
            !idFromUrl &&
            !regionaIDFromUrl &&
            !rajonaIDFromUrl &&
            !veidiFromUrl &&
            !nosaukDalaFromUrl &&
            !nosExactFromUrl &&
            !citataDalaFromUrl &&
            !nosOfficialFromUrl &&
            !nosLGIAApprovedFromUrl) {
            console.log(initialLoad)
            setInitialLoad(false)
            setIsLoading(false);
            return;
        }

        setIsLoading(true)
        try {
            if (initialLoad && (idFromUrl || regionaIDFromUrl || rajonaIDFromUrl || veidiFromUrl || nosaukDalaFromUrl)) {


                url = createURLFromSearchParameters(idFromUrl, regionaIDFromUrl, rajonaIDFromUrl, veidiFromUrl, nosaukDalaFromUrl, nosExactFromUrl, citataDalaFromUrl, nosOfficialFromUrl, nosNonAccentFromUrl, nosLGIAApprovedFromUrl);
                console.log("piepildās " + idFromUrl)
                //setInitialLoad(false);
            }
            else {

                url = createURL();

            }
            const id: number = fromString(url, fromString.URL);
            if (tabsRef.current.find(tab => tab.id === id)) {
                handleActiveTab(id);
                return;
            }

            const xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            // @ts-ignore
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                try {
                    const parsedResponse = JSON.parse(this.responseText);
                    if (Array.isArray(parsedResponse) && parsedResponse.length > 0) {
                        const obj = {
                            id,
                            data: [...parsedResponse]
                        };
                        setData(data => ({ ...data, id: obj.id, data: obj.data }));
                        setPreviousData(data);
                        newObjID.current = obj.data[0]?.objectID; // Fixing obj.data instead of obj[0]
                        handleSearchVisibility();
                        if (!initialLoad) {
                            updateURLWithSearchParams();
                        }
                        trackSearchEvent(searchValues);
                    } else {
                        setShowAlert(true);
                        showAlertFor3Seconds();
                        router.push("/search", undefined, { shallow: true });
                    }
                } catch (e) {
                    setShowAlert(true);
                    showAlertFor3Seconds();
                    console.error("Failed to parse response as JSON:", e);
                    console.error("Response text:", this.responseText);
                    router.push("/search", undefined, { shallow: true });
                }
            };

            xhr.onerror = () => {
                setShowAlert(true);
                showAlertFor3Seconds();
                console.error("Error occurred while fetching data");
                router.push("/search", undefined, { shallow: true });
            };

            xhr.send();

            await new Promise<void>((resolve, reject) => {
                xhr.onloadend = () => {
                    resolve();
                };
                xhr.onerror = () => {
                    reject(new Error("Error occurred while fetching data"));

                };
            });
        } catch (error) {
            setIsLoading(false);
            console.error("Error occurred while fetching data:", error);
        } finally {

            setIsLoading(false);

        }
    };

    const handleObjectFetchAPI = (rowObjectID: number) => {    //indivduālais objekts
        return new Promise((resolve, reject) => {
            try {
                const xhr = new XMLHttpRequest();
                xhr.open("GET", createObjectURL(rowObjectID), true);
                // @ts-ignore
                xhr.getResponseHeader("Content-type", "application/json");
                xhr.onload = function () {
                    const objectData = JSON.parse(this.responseText);
                    setObjectData(objectData);
                    resolve(objectData);
                };

                xhr.send();

                xhr.onloadend = () => {
                    setIsLoading(false);
                };
                xhr.onerror = () => {
                    reject(new Error("Error occurred while fetching data"));

                    setIsLoading(false);
                };
            } catch (error) {
                reject(error);
            }
        });
    };

    const handleFilterByMap = (newIds: any) => {
        /*         const newfilteredData: any = [];
                if (newIds) {
                    data.data.forEach(object => newIds.indexOf(object.objektaid) > -1 && newfilteredData.push(object));
                }
        
                const newData = {
                    id: data.id,
                    data: [...newfilteredData]
                }
        
                setFilteredData(prevData => ({ ...prevData, id: newData.id, data: newData.data }))
                setIsLoading(false); */
    }

    const handleExportExcel = (searchParams) => {
        let excelUrl = url.replace(/filterfull/g, 'Excel')
        window.open(excelUrl, '_blank');
        trackExcelCreateEvent(searchParams)

    }

    const currentYear = new Date().getFullYear();




    return (
        <GlobalContext.Provider value={{
            handleMenu,
            isMenuOpen,
            handleSearchVisibility,
            onOpenExplanation: handleOpenExplanation,
            tabComponents: tabComponents,
            isOpen: isMenuOpen,
            onMenu: handleMenu,
            activeTab: activeTab,
            setActiveTab: setActiveTab,
            tabCollection: tabCollection,
            onFilteredTabCollection: handleFilteredTabCollection,
            data: data,
            onFetchAPI: handleFetchAPI,
            handleFilterByMap: handleFilterByMap,
            onObjectFetchAPI: handleObjectFetchAPI,
            onSearchButtonClick: handleSearchButtonClick,
            onObjectIDSearch: handleObjectIDSearch,
            onObjectNameSearch: handleObjectNameSearch,
            onClearSearch: handleClearSearch,
            onFetchButtonClick: handleFetchAPI,
            checkboxes: checkboxes,
            onCheckboxChange: handleCheckboxChange,
            checkboxOptions: checkboxOptions,
            onObjectCountrySearch: handleObjectCountrySearch,
            countryData: countryData,
            onObjectMunicipalitySearch: handleObjectMunicipalitySearch,
            municipalityData: municipalityData,
            onObjectTypeSearch: handleObjectTypeSearch,
            setCountryID: setCountryID,
            municipalityOptions: municipalityOptions,
            isLoading: isLoading,
            uniqueTypes: uniqueTypes,
            searchValues: searchValues,
            showAlert: showAlert,
            setShowAlert: setShowAlert,
            showAlertFor3Seconds: showAlertFor3Seconds,
            searchVisibility: searchVisibility,
            idFieldActive: idFieldActive,
            data: data, //nomainīts no data: filteredData. var būt implications es nezinu kur
            filteredData,
            tabs: tabs,
            activeTab: activeTab,
            onActiveTab: handleActiveTab,
            onObjectViewCreate: handleObjectViewCreate,
            onTabAdd: handleTabAdd,
            onTabRemove: handleTabRemove,
            onTabCollection: handleTabCollection,
            filteredTabCollection: filteredTabCollection,
            rowObjectID: rowObjectID,
            rowNosaukums: rowNosaukums,
            rowVeids: rowVeids,
            setRowObjectID: setRowObjectID,
            onObjectFetchAPI: handleObjectFetchAPI,
            setObjectTabMap: setObjectTabMap,
            tabComponents: tabComponents,
            onExportExcel: handleExportExcel,
            searchValues: searchValues,
            showCookieDialog,
            showWarning,
            activeObjectId,
            idFromURL,
            updateURLWithSearchParams,
            setSearchValues,
            initialLoad,
            setInitialLoad,
            selectedURL
        }}>
            <Suspense fallback={<>Loading...</>}>
                {children}
            </Suspense>
        </GlobalContext.Provider>
    )
};

export const useGlobalContext = () => useContext(GlobalContext);