//@ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { vdbURL } from "@Utility/utilils";
import { convertToYesNo } from "@Utility/utilils";

function NamesObjectExpand({ newObjectID }: number) {




    const [namesData, setNamesData] = useState('')

    const createNamesURL = (newtestObjectID) => {
        let namesUrl = `${vdbURL}NOSAUKUMI/GetNosaukumiByObjekts/` + newtestObjectID;
        return namesUrl;
    }


    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createNamesURL(newObjectID), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                if (this.responseText) {
                    const newData = JSON.parse(this.responseText);
                    setNamesData(newData);
                }
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, []);


    const headers = [
        "Nosaukums",
        "Nosaukuma ID",
        "Nosaukums oficiāls",
        "Nosaukums ievadīts",
        "Nosaukuma forma",
        "Fonētiskā rakstība",
        "Lietošanas biežums",
        "Lietošanas vide",
        "Nosaukuma izcelsmes valoda",
        "Nosaukuma izcelsmes vārds",
        "Etimoloģija un semantika",
        "Izskaņa",
        "Senais nosaukums",
        "Oficiālais sākuma laiks",
        "Oficiālais beigu laiks",
        "Nosaukuma atvasināšana un pārceļošana",
        "Piezīmes",
    ];

    const dataToRender = Array.isArray(namesData) ? namesData : [];

    function decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    return (
        <div className="scroll-table">
            <table className="expand-table" style={{ width: "80vw" }}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataToRender.map((data, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{data.nosaukums}</td>
                            <td>{data.nosaukumaid}</td>
                            <td>{convertToYesNo(data.oficials)}</td>
                            <td>{data.inserT_DATE}</td>
                            <td>{data.nosaukumuForma.forma}</td>
                            <td>{decodeHtml(data.izruna)}</td>
                            <td>{data.lietosanasbiezums}</td>
                            <td>{data.lietosanasvide}</td>
                            <td>{data.izcelsme ? data.izcelsme.valoda.valoda : ''}</td>
                            <td>{data.izcelsme ? data.izcelsme.vards : ''}</td>
                            <td>{data.etimologija}</td>
                            <td>{data.izskana}</td>
                            <td>{data.pardevets}</td>
                            <td>{data.sakumalaiks}</td>
                            <td>{data.beigulaiks}</td>
                            <td>{data.atvasin ? data.atvasin.atvasinasanA1 : ''}</td>
                            <td>{data.komentari}</td>
                        </tr>
                    ))}
                </tbody>
            </table >
        </div>
    );
}

export default NamesObjectExpand;
