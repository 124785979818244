//@ts-nocheck
'use client';
import React, { useState, useEffect, useRef, Suspense } from "react";
import StaticInput from '../../components/_staticInput';
import { useEffectOnce } from "@Utility/customHook";
import { vdbURL } from '@Utility/utilils';

import ObjectExpandButton from "../../components/_object-expand_button";
import NamesObjectExpand from "../../components/object-expand/_names-object-expand";
import SourcesObjectExpand from "../../components/object-expand/_sources-object-expand";
import MapdataObjectExpand from "./_mapdata-object-expand";
import RiversObjectExpand from "../../components/object-expand/_rivers-object-expand";
import DescriptionObjectExpand from "../../components/object-expand/_description-object-expand";
import ContainingObjectExpand from "../../components/object-expand/_containing-object-expands";
import AddressObjectExpand from "../../components/object-expand/_address-object-expand";
import AdministrativeObjectExpand from "../../components/object-expand/_administrative-object-expand";
import HistoricalPopulation from "../../components/object-expand/_historical-population-expand";

import { useRouter } from 'next/navigation';

import { useGlobalContext } from "../Context/store";



function ObjectView({ objectId, rowObjectID, setRowObjectID, tabId }: any) {

    const { activeObjectId, idFromURL, updateURLWithSearchParams, showAlertFor3Seconds } = useGlobalContext();
    const router = useRouter();

    const [newObjectID, setNewObjectID] = useState(1)




    const updateURLWithRowObjectID = (activeObjectId: number) => {
        const queryParams = new URLSearchParams(window.location.search);
        if (activeObjectId) {
            queryParams.set('objectID', activeObjectId.toString());
            const newURL = `/search?objectID=${activeObjectId}`;
            router.push(newURL, undefined, { shallow: true });
            console.log(idFromURL)
        }

        else {
            console.log("vajadzētu atvērties updateURLWithSearchParams ")
            updateURLWithSearchParams()

        }

    }



    useEffect(() => {
        console.log("vai šis ir pirmais?")
        updateURLWithRowObjectID(activeObjectId);
    }, [activeObjectId]);


    const createObjectURL = (rowObjectID: number) => {
        let objectUrl = `${vdbURL}OBJEKTI/` + rowObjectID;
        setNewObjectID(rowObjectID)
        return objectUrl;

    }


    const createObjectExpandURL = (rowObjectID: number) => {
        let objectExpandUrl = `${vdbURL}OBJEKTI/GetSadaluSkaits/` + rowObjectID;
        setNewObjectID(rowObjectID)
        return objectExpandUrl;
    }
    /* 
        // Fetch data based on rowObjectID from the URL
        useEffect(() => {
            if (idFromURL) {
                // Assuming you have a function to fetch data based on rowObjectID
                fetchData(idFromURL).then(data => {
                    // Process the fetched data
                });
            }
        }, [idFromURL]); // Re-run this effect whenever id changes */

    const [accordion, setAccordion] = useState({
        nosaukumi: false,
        avoti: false,
        arisArtObjekts: false,
        iedzSkAgrak: false,
        raksturojums: false,
        upe: false,
        ietilpstosie: false,
        adreses: false,


    });

    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState();
    const [expandData, setExpandData] = useState();

    useEffectOnce(() => {
        async function getExpandData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createObjectExpandURL(objectId), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                const objectData = JSON.parse(this.responseText);
                setExpandData(prevExpandData => ({ ...prevExpandData, ...objectData }))
                //console.log(this.responseText)
            };

            xhr.send();

            xhr.onerror = () => {

                showAlertFor3Seconds();
            };
        }

        getExpandData();
    }, [])

    useEffectOnce(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", createObjectURL(objectId), true);
            xhr.setRequestHeader("Content-type", "application/json");

            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    const objectData = JSON.parse(xhr.responseText);
                    setData(prevData => ({ ...prevData, ...objectData }));
                } else {
                    // Handle non-2xx HTTP responses
                    console.error("Failed to fetch data: ", xhr.statusText);
                    showAlertFor3Seconds();
                    console.log("Show alert for 3 seconds");
                }
            };

            xhr.onerror = function () {
                console.error("Error occurred while fetching data");
                showAlertFor3Seconds();
                console.log("Show alert for 3 seconds");

            };

            xhr.send();
        }

        getData();
    }, []);

    useEffect(() => {
        if (data) {
            setLoading(false);
        }
    }, [data])



    if (isLoading) {
        return (
            <div className="object-view-container">Loading...</div>
        )
    }



    const toggleAccordion = (section) => {
        setAccordion((prevAccordion) => ({
            ...prevAccordion,
            [section]: !prevAccordion[section],
        }));
    };

    const isAnySectionOpen = Object.values(accordion).some((isOpen) => isOpen);

    const flipAllSections = () => {
        // Check if any section is open


        // Toggle all sections based on the current state
        if (isAnySectionOpen) {
            // If any section is open, turn off all sections
            setAccordion({
                nosaukumi: false,
                avoti: false,
                arisArtObjekts: false,
                raksturojums: false,
                iedzSkAgrak: false,
                upe: false,
                ietilpstosie: false,
                adreses: false,
            });
        } else {
            // If none is open, turn on all sections
            setAccordion({
                nosaukumi: true,
                avoti: true,
                arisArtObjekts: true,
                raksturojums: true,
                iedzSkAgrak: true,
                upe: true,
                ietilpstosie: true,
                adreses: true,
            });
        }
    }

    return (
        <>
            <Suspense fallback={<>Loading...</>}>
                <div className="object-view-container">

                    <div className="static">
                        <div className="column">
                            <h2 className='heading-secondary'>Informācija par objektu</h2>

                            <div className='static__form'>

                                <StaticInput label='Objekta ID:' id='id' data={data.objektaid} />

                                <StaticInput label='Pamatnosaukums' id='nosaukums' data={data.pamatnosaukumS_WITH_ATRIBS} />

                                <StaticInput label='Nosaukums Adrešu klasifikatorā' id='nosaukums-adr' data={data.nosaukumS_ADR_KLAS} />

                                <StaticInput label='Ietilpst (ietek) objektā' id='ietilpst-objekta' data={data.ietilpstnosaukums} />

                                <StaticInput label='Objekta veids' id='garums' data={data.veidS3} />

                                <StaticInput label='Objekta stāvoklis' id='platums' data={data.stavoklis} />

                                <StaticInput label='Pēdējo izmaiņu datums' id='apraksts' data={data.datumsizm} />



                                {/* <StaticInput label='Raksturojums' id='raksturojums' data={raksturojums} /> */}
                            </div>
                        </div>
                    </div>

                    <div className="static">
                        <div className="column">

                            <h2 className='heading-secondary'>Skaitliskais raksturojums</h2>
                            <div className='static__form'>

                                <StaticInput label='Vērtība' id='raksturojums' data={data.skaitL_RAKST_VERTIBA} />

                                <StaticInput label='Avots' id='avots' data={data.skaitL_RAKST_AVOTS} />

                                <StaticInput label='Apraksts' id='apraksts' data={data.rakstapraksts} />

                                {/* <StaticInput label='Sastādīšanas datums' id='apraksts' data={data.pamatnoS_PAREIZS_KAD} /> */}




                                {/*                             {data.veidS3 === "upe" && (
                                <StaticInput
                                    label='Ietilpst (ietek) objektā'
                                    id='ietilpst'
                                    data={`${data.ietilpstnosaukums} (Objekta ID: ${data.ietilpst})`}
                                />
                            )} */}

                            </div>
                        </div>
                    </div>
                    <div className="static">
                        <div className="column">
                            <h2 className='heading-secondary'>Administratīvā vai teritoriālā piederība</h2>
                            <div className='static__form'>
                                <StaticInput label='Pagasts' id='Pagasts' data={data.pagasts} />

                                <StaticInput label='Novads' id='Novads' data={data.novads} />

                            </div>
                        </div>
                    </div>
                    <div className="static">
                        <div className="column">
                            <h2 className='heading-secondary'>Ģeogrāfiskās koordinātas</h2>
                            <div className='static__form'>
                                <StaticInput label='Ģeogrāfiskais garums' id='garums' data={data.geogarumS_DMS} />

                                <StaticInput label='Ģeogrāfiskais platums' id='platums' data={data.geoplatumS_DMS} />
                            </div>
                        </div>
                    </div>

                </div >
                <div className="object-view-container-2">
                    <button className="show-more" onClick={() => flipAllSections()}>{isAnySectionOpen ? "Rādīt mazāk" : "Rādīt visus datus"} </button>

                    {
                        (
                            expandData ?
                                <div className="accordion">
                                    {expandData.nosaukumi !== 0 && expandData.nosaukumi !== false && expandData.nosaukumi !== undefined && (
                                        <>
                                            <ObjectExpandButton onClick={() => toggleAccordion('nosaukumi')}>
                                                Nosaukumi ({expandData.nosaukumi})
                                            </ObjectExpandButton>
                                            {accordion.nosaukumi && <NamesObjectExpand newObjectID={newObjectID} />}
                                        </>
                                    )}
                                    {expandData.avoti !== 0 && expandData.avoti !== false && expandData.avoti !== undefined && (
                                        <>
                                            <ObjectExpandButton onClick={() => toggleAccordion('avoti')}>Avoti ({expandData.avoti})</ObjectExpandButton>
                                            {accordion.avoti && <SourcesObjectExpand newObjectID={newObjectID} />}
                                        </>
                                    )}
                                    {expandData.iedzSkAgrak !== 0 && expandData.iedzSkAgrak !== false && expandData.iedzSkAgrak !== undefined && (
                                        <>
                                            <ObjectExpandButton onClick={() => toggleAccordion('iedzSkAgrak')}>Iedzīvotāju skaita vēsturiskie dati ({expandData.iedzSkAgrak})</ObjectExpandButton>
                                            {accordion.iedzSkAgrak && <HistoricalPopulation newObjectID={newObjectID} />}
                                        </>
                                    )}
                                    {expandData.arisArtObjekts !== 0 && expandData.arisArtObjekts !== false && expandData.arisArtObjekts !== undefined && (
                                        <>
                                            <ObjectExpandButton onClick={() => toggleAccordion('arisArtObjekts')}>Adrešu klasifikatora informācija</ObjectExpandButton>
                                            {accordion.arisArtObjekts && <AddressObjectExpand newObjectID={newObjectID} />}
                                        </>
                                    )}


                                    <ObjectExpandButton onClick={() => toggleAccordion('raksturojums')}>Objekta raksturojums, papildu ziņas </ObjectExpandButton>
                                    {accordion.raksturojums && <DescriptionObjectExpand newObjectID={newObjectID} />}

                                    {expandData.upe !== 0 && expandData.upe !== false && expandData.upe !== undefined && (
                                        <>
                                            <ObjectExpandButton onClick={() => toggleAccordion('upe')}>
                                                Ūdeņu dati ({expandData.upe ? 1 : ""})
                                            </ObjectExpandButton>
                                            {accordion.upe && <RiversObjectExpand newObjectID={newObjectID} />}
                                        </>
                                    )}
                                    {expandData.ietilpstosie !== 0 && expandData.ietilpstosie !== false && expandData.ietilpstosie !== undefined && (
                                        <>
                                            <ObjectExpandButton onClick={() => toggleAccordion('ietilpstosie')}>Ietilpstošie objekti ({expandData.ietilpstosie})</ObjectExpandButton>
                                            {accordion.ietilpstosie && <ContainingObjectExpand newObjectID={newObjectID} />}
                                        </>
                                    )}




                                </div> : null
                        )

                    }

                </div >
            </Suspense>
        </>
    );
}


export default ObjectView;