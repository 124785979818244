//@ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { vdbURL } from "@Utility/utilils";

function RiversObjectExpand({ newObjectID }: number) {




    const [riversData, setRiversData] = useState([])

    const createRiversURL = (newtestObjectID) => {
        let riversUrl = `${vdbURL}UPES/GetUpeByObjekts/` + newtestObjectID;
        return riversUrl;
    }

    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createRiversURL(newObjectID), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                if (this.responseText) {
                    const newData = JSON.parse(this.responseText);
                    setRiversData(newData);
                }
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, []);




    const headers = [
        "Baseina ID",
        "Ūdensteces baseins",
        "Upes pietekas veids",
        "Upes iztekas platums",
        "Upes iztekas garums",

    ];
    console.log(riversData.baseinaid)
    const dataToRender = [riversData];
    //console.log(dataToRender.length > 0 ? dataToRender[0].baseinaid : "No data available");
    //console.log(dataToRender);

    return (
        <div className="names-table">
            <table className="expand-table">
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataToRender.map((data, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{data.baseinaid}</td>
                            <td>{data.baseins ? data.baseins.baseins : 'N/A'}</td>
                            <td>{data.upesIetekasVeids ? data.upesIetekasVeids.nosaukums : 'N/A'}</td>
                            <td>{data.iztekasgeoplatums}</td>
                            <td>{data.iztekasgeogarums}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default RiversObjectExpand;
