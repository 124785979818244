import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const Ietilp = () => {
  return (
    <>
      <div className={styles["explanations-layout"]}>
        <h1>Ietilpstošie (ietekošie) objekti</h1>

        <p>
          <br />
          Laukā atrodas īsas ziņas par citiem ģeogrāfiskajiem objektiem, kas ietilpst (vai ietek, ja tā ir ūdenstece) konkrētajā objektā. Piemēram, Usmas ezerā atrodas Viskūžu sala; Irbes upē (Ventspils novads) ietek Rinda, Stende, Dižgrāvis.
          <br />
          <br />
          Šai laukā ir iekļauti tikai tie ietilpstošie vai ietekošie objekti, kuru nosaukumi atrodami Vietvārdu datubāzes publiskajā versijā un kuri ir savstarpēji sasaistīti.
        </p>
      </div>
    </>
  );
};

export default Ietilp;
