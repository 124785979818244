import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const VietvVeidi = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Paskaidrojumi - vietvārdu (ģeogrāfisko nosaukumu) veidi</h1>

                <table className="celled-table">
                    <tr>
                        <th>Pamatnosaukums</th>
                        <td>Ieteicamais ģeogrāfiskā objekta nosaukums, kas izvēlēts pēc vispusīgas esošo nosaukumu analīzes. Atsevišķos gadījumos var būt divi pamatnosaukumi</td>
                    </tr>

                    <tr>
                        <th>Citi nosaukumi</th>
                        <td>Citi zināmie objekta nosaukumi (t. sk. oficiālais nosaukums, ja tas lielā mērā neatbilst Vietvārdu informācijas noteikumu prasībām, paralēlie nosaukumi, nosaukumu varianti, agrākie, vēsturiskie nosaukumi, kļūdainie nosaukumi, nosaukumi izloksnē, latgaliešu rakstu valodā, citās valodās u. tml.)</td>
                    </tr>
                </table>
                <br />

                <p>
                    Nosaukums ir oficiāls, ja tas ir apstiprināts LR normatīvo aktu noteiktajā kārtībā.
                    Valsts Zemes dienesta Valsts Adrešu reģistrā klasifikatorā iekļautais nosaukums, ja tā statuss ir &quot;eksistējošs&quot;, ir oficiāls nosaukums. Skat. <a href="https://www.kadastrs.lv/?lang=0&amp;cPath=3&amp;txt_id=24">kadastrs.lv</a>
                </p>
            </div >
        </>
    );
};

export default VietvVeidi;
