//@ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { vdbURL } from "@Utility/utilils";

function DescriptionObjectExpand({ newObjectID }) {
    // Assuming objectExpandData is the API response you receive

    const [descriptionData, setDescriptionData] = useState('')

    const createDescriptionURL = (newObjectID) => {
        let descriptionUrl = `${vdbURL}OBJEKTI/GetZinas/` + newObjectID
        return descriptionUrl;
    }

    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createDescriptionURL(newObjectID), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                if (this.responseText) {
                    const newData = JSON.parse(this.responseText);
                    setDescriptionData(newData);
                }
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, [/* objectId */]);



    const headers = [
        "Objekta raksturojums",
        "Papildu ziņas par objektu",
        "Papildu ziņas par nosaukumu",

    ];

    const dataToRender = [descriptionData];




    return (
        <div className="names-table">
            <table className="expand-table">
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataToRender.map((data, rowIndex) => (
                        <tr key={rowIndex}>


                            <td>{data.raksturojums}</td>
                            <td>{data.zinas}</td>
                            <td>{data.zinasnosaukums}</td>

                            {/* <td>{data.apraksts}</td> */}

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DescriptionObjectExpand;
