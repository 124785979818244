//@ts-nocheck
import React, { useEffect, useState } from "react";
import styles from "../../sass/layout/_explanations.module.scss";
import { vdbURL } from "@Utility/utilils";



const ObjVeidi = () => {

    const [objectTypeExpandData, setObjectTypeExpandData] = useState('')

    const createObjectTypeExpandData = () => {
        let objectUrl = `${vdbURL}VEIDI_PASKAIDROJUMS`
        return objectUrl;
    }

    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createObjectTypeExpandData(), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                const newData = JSON.parse(this.responseText);
                setObjectTypeExpandData(newData);
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, [/* objectId */]);



    const headers = [
        "I līmenis objektu grupa",
        "II līmenis objekta tips",
        "III līmenis objekta veids",
        "Paskaidrojums Vietvārdu datubāzes izpratnē",
        "Vai datubāzē iekļauti visi attiecīgā veida objekti, kam zināmi nosaukumi",

    ];



    const dataToRender = Array.isArray(objectTypeExpandData) ? objectTypeExpandData : [];

    return (
        <>
            <div className="scroll-table">
                <div className={styles["explanations-layout"]}>
                    <h1>Objektu veidi</h1>


                    <table className="expand-table">
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {dataToRender.map((data, rowIndex) => (
                                <tr key={rowIndex}>


                                    <td>{data.objektuGrupa}</td>
                                    <td>{data.objektaTips}</td>
                                    <td>{data.objektaVeids}</td>
                                    <td>{data.paskaidrojums}</td>
                                    <td>{data.ieklausanasKriteriji}</td>


                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div >
            </div >
        </>
    );
};

export default ObjVeidi;
