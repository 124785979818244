//@ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { vdbURL } from "@Utility/utilils";

function SourcesObjectExpand({ newObjectID }) {
    // Assuming objectExpandData is the API response you receive

    const [sourcesData, setSourcesData] = useState('')

    const createSourcesURL = (newObjectID) => {
        let sourcesUrl = `${vdbURL}AVOTI/GetAvotiByObjekts/` + newObjectID
        return sourcesUrl;
    }

    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", createSourcesURL(newObjectID), true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                if (this.responseText) {
                    const newData = JSON.parse(this.responseText);
                    setSourcesData(newData);
                }
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, [/* objectId */]);



    const headers = [
        "Avota veids",
        "Avota saīsinājums",
        "Avota pilns nosaukums",
        //"Sējums, lappuse vai individuālā avota pieraksts", vajadzētu būt šeit, bet nav datubāzē
        "Citāts no avota",

    ];

    const dataToRender = Array.isArray(sourcesData) ? sourcesData : [];



    return (
        <div className="scroll-table">
            <table className="expand-table">
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataToRender.map((data, rowIndex) => (
                        <tr key={rowIndex}>


                            <td>{data.avotuSaraksts.avotuVeids.avotaveids}</td>
                            <td>{data.avotuSaraksts.avotsisi}</td>
                            <td>{data.avotuSaraksts.avots}</td>
                            <td>{data.apraksts}</td>
                            {/* <td>{data.apraksts}</td> */}

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default SourcesObjectExpand;
