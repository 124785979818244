//@ts-nocheck
import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const Ieteikumi = () => {
    return (
        <>
            <div className={styles["explanations-layout"]}>
                <h1>Ieteikumi vietvārdu jomā</h1>
                <br />
                <p><a href="http://www.vvk.lv/index.php?sadala=208&id=651" target="_blank">Valsts valodas centra ieteikumi. Par ciemu, viensētu, ēku un citu vietu nosaukumu (vietvārdu) veidošanu, rakstību un lietošanu oficiālos dokumentos</a></p>
                <p><a href="https://unstats.un.org/unsd/publication/seriesm/seriesm_88e.pdf" target="_blank">Nacionālās vietvārdu standartizācijas rokasgrāmata (angļu val.) - Manual for the National Standardization of Geographical Names

                </a></p>
            </div >
        </>
    );
};

export default Ieteikumi;
