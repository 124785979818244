// @ts-nocheck
import React from 'react';

export default function StaticInput({ label = '', id = '', data }) {
    return (
        <div className='static__p-container'>
            <label className='static__p-label' htmlFor={id}>{label}</label>
            <div className='static__p-text' type='text' id={id} >{data}</div>
        </div>
    );
}