import React from "react";
import styles from "../../sass/layout/_explanations.module.scss";

const AdmPieder = () => {
  return (
    <>
      <div className={styles["explanations-layout"]}>
        <h1>Administratīvā piederība</h1>
        <br />
        <p>
          Katram datubāzē esošajam ģeogrāfiskajam objektam norādīta administratīvā piederība.
          <br /><br />
          Norādīta zemākā līmeņa administratīvā vai teritoriālā vienība, kurā objekts atrodas. Ja objekts atrodas vairākās, tad trekninātā drukā norādīta viena galvenā vienība (ūdenstecēm – tā, kurā atrodas ieteka; laukumveida objektiem – tā, kurā atrodas objekta lielākā daļa) un papildus norādītas visas pārējās, kurās tas atrodas.
          <br /><br />
          Kaimiņvalstu teritorijā esošam objektam vai objekta daļai norādīta valsts: Lietuva, Baltkrievija, Krievija vai Igaunija. Ļoti lieliem laukumveida objektiem (piemēram, upju baseinu apgabaliem, augstienēm u. tml.) norādīta tikai valsts, neuzskaitot administratīvā un teritoriālā iedalījuma vienības.
          <br /><br />
          Administratīvā piederība tiek norādīta atbilstoši spēkā esošajam Latvijas Republikas administratīvajam un teritoriālajām iedalījumam (skat. Administratīvo teritoriju un apdzīvoto vietu likumu (10.06.2020.) un Centrālās statistikas pārvaldes izstrādātā klasifikatora ATVK aktuālo versiju.).
        </p>
      </div>
    </>
  );
};

export default AdmPieder;
