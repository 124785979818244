//@ts-nocheck

import ReactGA from "react-ga4";


export const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const convertToYesNo = (value: number) => {
    return value === 1 || value === -1 ? 'Jā' : 'Nē';
}


export const currentYear = new Date().getFullYear();

const latvianAlphabetOrder = '0123456789AaĀāBbCcČčDdEeĒēFfGgĢģHhIiĪīJjKkĶķLlĻļMmNnŅņOoPpRrSsŠšTtUuŪūVvZzŽž';

export const customLatvianSort = (a, b) => {
    const getIndex = char => latvianAlphabetOrder.indexOf(char);

    for (let i = 0; i < Math.min(a.length, b.length); i++) {
        const charA = a[i];
        const charB = b[i];

        const indexA = getIndex(charA);
        const indexB = getIndex(charB);

        if (indexA !== indexB) {
            return indexA - indexB;
        }
    }

    return a.length - b.length;
};

export const trackSearchEvent = (searchValues) => {
    
    console.log('track search event izslēgts') 
     if (localStorage.getItem('cookies') === 'accept'){
        const searchValuesConvert = Object.values(searchValues)
        const searchValuesArray = searchValuesConvert.join(', ')

        window.gtag('event', 'search', {
            event_category: 'Search',
            event_label: searchValuesArray
        }); 
} }


export const trackExcelCreateEvent = (searchQuery) =>{
    

        if (localStorage.getItem('cookies') === 'accept'){  
            window.gtag('event', 'export', {
            event_category: 'Export',
            event_label: String(searchQuery),
            test_label: String(searchQuery),
            
    }

)
}}

//testiURL = 'https://testi.lgia.gov.lv/vdbpubapi/api/'
//pubURL = 'https://testvietvardi.lgia.gov.lv/api/api/'
//wereLiveBoysLink = 'https://vietvardi.lgia.gov.lv/api/api/'

export const vdbURL = 'https://vietvardi.lgia.gov.lv/api/api/'
