//@ts-nocheck
import React, { useState, useEffect } from "react";
import { vdbURL } from "@Utility/utilils";



function WelcomeWindow() {

    const [lastUpdateData, setLastUpdateData] = useState([])


    let lastUpdateUrl = `${vdbURL}DBInfo`


    useEffect(() => {
        async function getData() {
            const xhr = new XMLHttpRequest();
            await xhr.open("GET", lastUpdateUrl, true);
            xhr.getResponseHeader("Content-type", "application/json");
            xhr.onload = function () {
                if (this.responseText) {
                    const newData: any = JSON.parse(this.responseText);
                    setLastUpdateData([newData]);
                }
            };

            xhr.send();

            xhr.onerror = () => {
                reject(new Error("Error occurred while fetching data"));
            };
        }

        getData();
    }, []);

    const dataToRender = Array.isArray(lastUpdateData) ? lastUpdateData : [];


    return <>
        <div className='welcome-container'>
            <h1><br />Laipni lūgti Vietvārdu datubāzē.<br /><br /> </h1>
            <span className="welcome-text">
                Datubāzē tiek uzturētas ziņas par vietvārdiem (ģeogrāfiskajiem nosaukumiem jeb toponīmiem) un tiem atbilstošajiem ģeogrāfiskajiem objektiem – gan dabas, gan cilvēku veidotiem. LĢIA Vietvārdu datubāzes darba versijā ir uzkrāti 185 tūkstoši vietvārdu, kas attiecas uz 132 tūkstošiem ģeogrāfisko objektu (01.01.2024.). Informācija aptver visu Latvijas teritoriju, kā arī kaimiņvalstu pierobežas zonu.             </span >
            <span className="welcome-text"> Datubāzes publiskajā versijā nav iekļauti vietvārdi kaimiņvalstu teritorijās, kā arī tādi, kuriem datubāzē trūkst kāda būtiski svarīga informācija, piemēram, nav zināma objekta atrašanās vieta.
            </span >
            <span className="welcome-text">
                <br /><br />Pēdējo izmaiņu datums: {dataToRender[0]?.date}<br />Rakstiet mums: vdb.info@lgia.gov.lv
            </span >
        </div>
    </>
}

export default WelcomeWindow;